<template>
  <section class="col-lg-9 m-auto montserrat-regular">
    <div class="pt-5 text-center">
      <div class="heading text-center">
        <h2>
          <span style="background-color: var(--tertiary-blue)">
            SOMOS UN BROKER QUE ESCUCHA
          </span>
        </h2>
        <p class="intro">
          Analizamos riesgos y diseñamos el mejor programa de seguros a medida
          para cada cliente.
        </p>
      </div>
    </div>
    <div class="row mb-5 points">
      <ul class="col-md-6 col-sm-12 px-5">
        <li>Gestión de siniestros.</li>
        <li>
          Relevamiento, análisis y mediación de riesgos (Recomendaciones para
          su reducción o eliminación).
        </li>
        <li>Diseño, aprobación y cotización de programas de seguros.</li>
      </ul>
      <ul class="col-md-6 col-sm-12 px-5">
        <li>Seguimiento, actualización y asesoramiento permanente.</li>
        <li>
          Ofrecemos grupos de afinidad para empleados que incluyen seguros de
          auto, hogar, vida y retiro con beneficios exclusivos y asesoramiento
          constante.
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "SomosText",
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
