<template>
  <section class="container col-lg-9 montserrat-regular">
    <div class="col heading text-center">
      <h2>
        <span style="background-color: var(--dark-blue)"> CONOCENOS </span>
      </h2>
    </div>
    <div class="row py-4 text-center text-md-left justify-content-lg-between">
      <div class="know-us-card-container montserrat-medium">
        <div>
          <img src="../assets/esteban.jpg" alt="" />
        </div>
        <div class="col-lg-7 d-md-flex flex-column justify-content-md-center">
          <h6 class="font-600">Lic. ESTEBAN NACHT</h6>
          <span class="color-gray">Socio - Director</span>
        </div>
      </div>
      <div class="know-us-card-container montserrat-medium">
        <div>
          <img src="../assets/leandro.jpg" alt="" />
        </div>
        <div class="col-lg-8 d-flex flex-column justify-content-md-center">
          <h6 class="font-600">Lic. LEANDRO DONOFRIO</h6>
          <span class="color-gray">Socio - Director</span>
        </div>
      </div>
    </div>
  </section>
</template>
