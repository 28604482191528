<template>
  <MainBanner class="d-none d-md-flex" :title="mainTitle" />
  <img class="w-100 montserrat-regular d-md-none" :src="images.mainMobile" />

  <SecondSection :information="secondSection" />
  <SomosText />
  <Banner :banner="thirdBanner" />
  <CareerPlan class="my-5" />
  <KnowUs />
</template>

<script>
import MainBanner from "@/components/MainBanner.vue";
import SecondSection from "@/components/SecondSection.vue";
import SomosText from "@/components/SomosText.vue";
import Banner from "@/components/Banner.vue";
import CareerPlan from "@/components/CareerPlan.vue";
import KnowUs from "@/components/KnowUs.vue";

import mainBannerImage from "@/assets/main-banner-somos-zama-01.png";
import secondBannerImage from "@/assets/second-banner-somos-zama-01.png";
import thirdBannerImage from "@/assets/third-banner-somos-zama-01.png";
import mainMobile from "@/assets/mobile/sobre-zama-m.png";

export default {
  name: "WeAre",
  components: {
    MainBanner,
    SecondSection,
    SomosText,
    Banner,
    CareerPlan,
    KnowUs,
  },
  data() {
    return {
      images: { mainMobile },
      mainTitle: {
        first: "FORTALEZA",
        second: "EN SEGUROS",
        img: mainBannerImage,
        bgColor: "var(--dark-blue)",
      },
      secondSection: {
        goto: "productores",
        gotoId: "#vitaeForm",
        title: "SEGURIDAD PARA VOS Y TU EMPRESA",
        text: "Somos un equipo de profesionales con fuerza dedicado al asesoramiento de seguros. En Zama tenemos rituales que nos permiten comprender cada momento, garantizando tu seguridad y la de tu empresa frente a cualquier imprevisto.",
        centerWord: "UBUNTU",
        bannerTitleOne: "En ZAMA",
        bannerTitleSecond: "te cuidamos.",
        bannerText:
          "Es una filosofía africana cuyo significado refleja 'Humanidad hacia otros'. La palabra proviene de las lenguas Zulu y Xhosa. Es un modo de vida enfocado en el respeto, la lealtad y cuidado de las personas",
        img: secondBannerImage,
        bgColor: "var(--secondary-blue)",
      },
      thirdBanner: {
        goto: "productores",
        gotoId: "#byPartOfZama",
        bannerTitleOne: "Pertenecé",
        bannerTitleSecond: "a nuestra tribu.",
        bannerText:
          "Desarrollá tu propio negocio comercializando Seguros de las mejores compañías líderes del mercado y convertite en productor de Zama. Sentí el respaldo de un Broker con Fortaleza en el mercado.",
        img: thirdBannerImage,
        bgColor: "var(--dark-blue)",
        btn: "UNITE",
      },
    };
  },
};
</script>
