<template>
  <section class="montserrat-regular" style="text-align: center">
    <div class="container py-4 text-center">
      <div class="col heading text-center">
        <h2>
          <span style="background-color: var(--dark-blue)">
            NUESTRO PLAN DE CARRERA
          </span>
        </h2>
      </div>
    </div>
    <section class="container text-center col-lg-9 montserrat-regular">
      <div class="row spartan-regular">
        <div class="col-md-6 col-lg-4 col-sm-12 pt-4 spartan-regular">
          <div class="boxBlue d-flex align-items-center flex-column">
            <img class="w-100" src="../assets/beneficios-01.png" alt="" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-sm-12 pt-4 spartan-regular">
          <div class="boxBlue d-flex align-items-center flex-column">
            <img class="w-100" src="../assets/beneficios-02.png" alt="" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-sm-12 pt-4 spartan-regular">
          <div class="boxBlue d-flex align-items-center flex-column">
            <img class="w-100" src="../assets/beneficios-03.png" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="container text-center col-lg-9 montserrat-regular">
      <div class="row spartan-regular">
        <div class="col-md-6 col-lg-4 col-sm-12 pt-4 spartan-regular">
          <div class="boxBlue d-flex align-items-center flex-column">
            <img class="w-100" src="../assets/beneficios-04.png" alt="" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-sm-12 pt-4 spartan-regular">
          <div class="boxBlue d-flex align-items-center flex-column">
            <img class="w-100" src="../assets/beneficios-05.png" alt="" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-sm-12 pt-4 spartan-regular">
          <div class="boxBlue d-flex align-items-center flex-column">
            <img class="w-100" src="../assets/beneficios-06.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
